<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>在线报班</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">在线报班<a-badge :count="0" /></span>
          <zxbb v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">报班记录<a-badge :count="0" /></span>
          <bbjl v-if="activeKey==='2'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="3">
          <span slot="tab">报班分析<a-badge :count="0" /></span>
          <bbfx v-if="activeKey==='3'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import zxbb from './zxbb/index'
import bbjl from './bbjl/index'
import bbfx from './bbfx/index'

export default {
  components: {
    zxbb,
    bbjl,
    bbfx,
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

