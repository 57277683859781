<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>招募名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.fuzzy" placeholder="招募名称" style="width: 200px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>招募校区</span>
              </template>
              <a-select allowClear v-model="searchData.studio_id" placeholder="请选择招募校区" showSearch :filterOption="filterOption" style="width: 200px">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>招募状态</span>
              </template>
              <a-radio-group :value="searchData.recruit_status">
                <a-radio-button v-for="(d, index) of recruitStatus" :key="index" :value="d['value']" @click="handleTypeButChange(d,'recruit_status')">
                    {{ d['label'] }}
                </a-radio-button>
              </a-radio-group>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="class_online_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1300 }" @change="handleChange">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="image" slot-scope="text, record">
            <LImg :src="text" style="width: 100px; height: 42px;"/>
          </template>

          <template slot="status" slot-scope="text,record">
            <a-switch checkedChildren="招募中" unCheckedChildren="已下线" :checked="text=== 1" @change="changeStatus(record)"/>
          </template>


          <template slot="recruit_start" slot-scope="text,record">
            <div>{{record.recruit_start}}</div>
            <div>{{record.recruit_end}}</div>
          </template>


          <template slot="recruit_total" slot-scope="text,record">
            <a @click='showStudent(record)'>{{record.reserve_count}} / {{record.recruit_total}}</a>
          </template>

          <template slot="action" slot-scope="text,record">

            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
              <a-menu slot="overlay">
                <a-menu-item v-if="canUpdate" key="1" @click="showEditModal(record)">
                  招募编辑
                </a-menu-item>
                <a-menu-item key="2" >
                  <a href="javascript:;" @click="showCopyModal(record)">复制链接</a>
                </a-menu-item>
                <a-menu-item v-if="canSave" key="3" @click="showEditModal(record,1)">
                  复制招募
                </a-menu-item>
              </a-menu>
            </a-dropdown>

          </template>

      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :isEdit='isEdit' :isCopy='isCopy' :item="modalData"/>
    <student v-if="isStudent && canRead" :item="studentData"/>
    <copyModal v-if="isCopyModal" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '招募头图', dataIndex: 'recruit_cover', key: 'name',scopedSlots: { customRender: 'image' }, },
  { title: '招募名称', dataIndex: 'recruit_name', key: '2'},
  { title: '招生班级', dataIndex: 'classes_id', key: '1' },
  { title: '招募校区', dataIndex: 'studio_id', key: '3' ,ellipsis:true},
  { title: '课程科目', dataIndex: 'course_cate_name', key: '6' ,ellipsis:true,sorter:()=>{}},
  { title: '招生统计', dataIndex: 'recruit_total', key: '7' ,scopedSlots: { customRender: 'recruit_total' },align:"right",sorter:()=>{}},
  { title: '非学员价', dataIndex: 'sale_price', key: '10',align:"right" ,sorter:()=>{}},
  { title: '学员价', dataIndex: 'member_price', key: '11' ,align:"right",sorter:()=>{}},
  { title: '招募时间', dataIndex: 'recruit_start',scopedSlots: { customRender: 'recruit_start' } ,key: '13' ,align:'center',sorter:()=>{}},
  { title: '添加人', dataIndex: 'created_by', key: '8',align:'center'},
  { title: '招募状态', dataIndex: 'recruit_status', key: '14' ,scopedSlots: { customRender: 'status' }, align: 'right'},
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' }},
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import copyModal from './copyModal'
import student from './student'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    student,
    editModal,
    copyModal
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,
      isEdit:0,
      isCopy:0,
      recruitStatus: C_ITEMS.recruitStatus,
      studios: [],

      searchData: {},
      isStudent:false,
      isCopyModal:false,
      visible: false,
      modalData: {},
      studentData:{},
      editVisible: false,
      authType:['market','weipages'],
    }
  },
  mixins: [ authority, tableMixins ],
  created() {
    this.getStudio()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('weipagesRecruitAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item,num) {
      if(item){
        if(num){
          this.isCopy = 1
          this.isEdit = 0
        }else{
          this.isCopy = 0
          this.isEdit = 1
        }
      }else{
        this.isEdit = 0
        this.isCopy = 0
      }
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要启用招募班级"${item.recruit_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('weipagesRecruitRecoveryAction', { data: { class_online_id: item.class_online_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },

    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要禁用招募班级"${item.recruit_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('weipagesRecruitDeleteAction', { data: { class_online_id: item.class_online_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.field}`
            }else{
                this.searchParams['sort'] = `-${sorter.field}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList()
    },
    handleTypeButChange(e,name){
      if(this.searchData[name] == e.value){
        this.searchData[name] = undefined
      }else{
        this.searchData[name] = e.value
      }
      this.searchList()
    },
    changeStatus(record){
      if (record.recruit_status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },

    showStudent(val){
      this.studentData = val
      this.isStudent = true
    },
    hideStudent(val){
      this.isStudent = false
    },
    showCopyModal(item){
      this.modalData = item
      this.isCopyModal = true
    },
    hideCopyModal(){
      this.isCopyModal = false
    }

  }
}
</script>
