<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="42.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number style="width:100%" v-if="item.type === 'number'" :min="0" v-decorator="[item.name, { rules: item.rules }]"/>

        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        
        <div v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'upload2'" style="display: inline-block; position: relative;">
          <a-button type="dashed" icon="file-image" @click="showImagesModal(item.name)">选择图片</a-button>
          <div style="display: flex; flex-wrap: wrap;">
            <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="index">
              <LImg :src="img" style="width: 100px; height: 100px;"/>
              <div class="close" @click="closeImg2($event, index)">&times;</div>
            </div>
          </div>
        </div>

        <a-cascader
        v-else-if="item.type === 'cascader'"
        :options="seleteItems[item.items.data]"
        :show-search="{ filterOption }"
        v-decorator="[item.name, { rules: item.rules }]"
        placeholder="请选择"
        :popupStyle='{zIndex:4200}'
        @change="onChangeCascader">
            <template slot="displayRender" slot-scope="{labels}">
                <span>{{labels[1]}}</span>
            </template>
        </a-cascader>

        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  :show-time="{ format: 'HH:mm' }" v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD HH:mm"/>
        <div v-else-if="item.type === 'select-class'">
          <a-select v-if='isEdit != 1' showSearch @search="handleSearchClass" :filterOption="filterOption" placeholder="请选择" @change="getClassRecruitFee"
            v-decorator="[item.name, { rules: item.rules }]">
            <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
          </a-select>
          <span v-else>{{class_name.class_name}}</span>
        </div>
        <div v-else-if="item.type === 'priority_related_class'">
          <a-select showSearch @search="handleSearchClass" mode="multiple" :filterOption="filterOption" placeholder="请选择"
            v-decorator="[item.name, { rules: item.rules }]">
            <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
          </a-select>
        </div>
        <div v-else-if="item.type === 'select-fee'">
          <a-select v-if='isEdit != 1' placeholder="请选择" showSearch :filterOption="filterOption" v-decorator="[item.name, { rules: item.rules }]">
            <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
          </a-select>
          <span v-else>{{fee_title.fee_title}}</span>
        </div>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-select v-else-if="item.type === 'survey'" placeholder="请选择" :not-found-content="fetching ? undefined : null" 
           v-decorator="[item.name, { rules: item.rules }]" :filterOption="filterOption" @search="handleSearch" showSearch allowClear>
           <template slot="notFoundContent">
              <div style="height:50px">
                  <vue-element-loading :active="fetching" color="#00cca2" spinner="spinner"/>
              </div>
          </template>
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <l-editor v-decorator="[item.name, { rules: item.rules }]" v-else-if="item.type === 'textarea'" name="recruit_description" :data="recruit_description"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
function numberCheck(rule, value, callbackFn){
  if (value<=0) {
    callbackFn('数值需要大于0!')
    return
  }
  callbackFn()
}

const formData = [
  {
    name: 'recruit_cover',
    label: '招募头图', 
    type: 'upload',
    rules: [{ required: true, message: '请输入招募头图!' }]
  },
  {
    name: 'recruit_name',
    label: '招募名称',
    type: 'input',
    rules: [{ required: true, message: '请输入招募名称!' }]
  },
  {
    name: 'classes_id',
    label: '招生班级',
    type: 'select-class',
    rules: [{ required: true, message: '请输入招生班级!' }],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'class_id'
    }
  },
  {
    name: 'fee_id',
    label: '课程收费',
    type: 'select-fee',
    rules: [{ required: true, message: '请输入课程收费!' }],
    items: {
      data: 'recruitFees',
      label: 'fee_name',
      value: 'fee_id'
    }
  },
  {
    name: 'recruit_telphone',
    label: '招募电话',
    type: 'input',
    rules: [{ required: true, message: '请输入招募电话!' }]
  },
  {
    name: 'sale_price',
    label: '非学员价',
    type: 'number',
    rules: [{ required: true, message: '请输入非学员价!' },{validator:numberCheck.bind(this)}]
  },
  {
    name: 'member_price',
    label: '学员价格',
    type: 'number',
    rules: [{ required: true, message: '请输入学员价格!' },{validator:numberCheck.bind(this)}]
  },
  {
    name: 'origin_price',
    label: '招募原价',
    type: 'number',
    rules: [{ required: true, message: '请输入招募原价!' },{validator:numberCheck.bind(this)}]
  },
  {
    name: 'recruit_total',
    label: '招募名额',
    type: 'number',
    rules: [{ required: true, message: '请输入招募名额!' }]
  },
  {
    name: 'recruit_start',
    label: '开始时间',
    type: 'date',
    rules: [{ required: true, message: '请输入开始时间!' }]
  },
  {
    name: 'recruit_end',
    label: '结束时间',
    type: 'date',
    rules: [{ required: true, message: '请输入结束时间!' }]
  },
  {
    name: 'survey_id',
    label: '信息采集',
    type: 'survey',
    items: {
      data: 'survey',
      label: 'filter_name',
      value: 'survey_id'
    }
  },
  {
    name: 'related_channel_id',
    label: '来源渠道',
    type: 'cascader',
    rules: [],
    items: {
      data: 'channels',
      label: 'filter_name',
      value: 'source_channel_id'
    }
  },
  {
    name: 'priority_related_class',
    label: '优先班级',
    type: 'priority_related_class',
    rules: [],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'class_id'
    }
  },
  // {
  //   name: 'recruit_images',
  //   label: '招募多图',
  //   type: 'upload2',
  //   rules: [{ required: true, message: '请输入招募多图!' }]
  // },
  {
    name: 'recruit_description',
    label: '招募内容',
    type: 'textarea',
    rules: [{ required: true, message: '请输入招募内容!' }]
  },
]

import moment from 'moment'
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    isEdit:Number,
    isCopy:Number,
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      loading:false,
      fetching: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增在线报班',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: [],
        recruitFees: [],
        survey:[],
        channels:[],

      },
      recruit_description: '',
      detailData:'',
      imageUrl: '',
      imageUrls: [],
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
      editor: {},
      fee_title:'',
      class_name:'',
      channelsId:[],
    }
  },

  async created() {
    this.visible = true
    if (this.item) {
      this.loading = true
    }
    await this.$nextTick()
    await this.getClassCategory()
    await this.getSurvey()
    if (this.item) {
      this.ModalTitle = '编辑在线报班'
      let item = await this.getDetail(this.item.class_online_id)
      let formFields = {}
      for (let d of formData) {
        if (['recruit_start', 'recruit_end'].indexOf(d.name) !== -1) {
          formFields[d.name] = moment(item[d.name])
        } else if(['recruit_cover'].indexOf(d.name) !== -1) {
        } else {
          formFields[d.name] = item[d.name]
        }
      }
      this.imageUrl = item['recruit_cover']
      this.imageUrls = item['recruit_images']
      this.recruit_description = item['recruit_description']
      delete formFields.recruit_cover
      this.getClassRecruitFee(formFields.classes_id)
      formFields.classes_id = formFields.classes_id.toString()
      if(this.isCopy != 1){
        delete formFields.classes_id
        delete formFields.fee_id
      }
      delete formFields.related_channel_id
      this.form.setFieldsValue(formFields)
      this.getSourceChannel(item.related_channel_id)
    }else{
      this.getSourceChannel()
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getClassCategory(val) {
      let res = await this.$store.dispatch('searchClassesAction',{params:val})
      this.seleteItems.classCategorys = res.data
    },
    async getClassRecruitFee(class_id) {
      let res = await this.$store.dispatch('searchClassRecruitFeeAction', { params: { class_id } })
      this.seleteItems.recruitFees = res.data
    },
    async getDetail(class_online_id) {
      let res = await this.$store.dispatch('weipagesRecruitDetailAction', {data: {class_online_id}})
      this.loading = false
      this.class_name = res.data.classes
      this.fee_title = res.data.fee
      return res.data
    },
    async getSurvey(obj) {
      this.fetching = true
      let res = await this.$store.dispatch('searchSurveyAction', {params:obj})
      this.fetching = false
      this.seleteItems.survey = res.data
    },
    async getSourceChannel(id) {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', { })
      this.seleteItems.channels = res.data[0].children
      if(id){
        res.data[0].children.forEach(item=>{
          item.children.forEach(it=>{
            if(it.value == id){
              this.channelsId = [item.value,it.value]
              this.form.setFieldsValue({related_channel_id:this.channelsId})
            }
          })
        })
      }
    },
    changeEditor(key, value) {
      this.editor[key] = value
      this.form.setFieldsValue({recruit_description:value})
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        this.confirmLoading=true
        if (params) {
          if (item && this.isCopy !=1) {
            params.class_online_id = item.class_online_id
            params.classes_id = this.class_name.class_id
            params.fee_id = this.fee_title.fee_id
          }
          if(this.channelsId.length>0){
            params.related_channel_id = this.channelsId[1]
          }
          if(!params.survey_id){
            params.survey_id = ''
          }
          params.recruit_start = moment(params.recruit_start).format('YYYY-MM-DD HH:mm')
          params.recruit_end = moment(params.recruit_end).format('YYYY-MM-DD HH:mm')
          params.recruit_cover = this.imageUrl
          params.recruit_images = this.imageUrls
          params.recruit_description = this.editor.recruit_description
          if(this.isCopy == 1){
            await this.$store.dispatch('weipagesRecruitAddAction', { data: params })
            .then(res=>{
              this.$message.success('操作成功~')
              form.resetFields()
              this.parent.hideEditModal(1)
            })
            .catch(err=>{
              this.$message.warning('操作失败~')
              this.confirmLoading=false
            })
          }else{
            await this.$store.dispatch(item ? 'weipagesRecruitUpdateAction' : 'weipagesRecruitAddAction', { data: params })
            .then(res=>{
              this.$message.success('操作成功~')
              form.resetFields()
              this.parent.hideEditModal(1)
            })
            .catch(err=>{
              this.$message.warning('操作失败~')
              this.confirmLoading=false
            })
          }
        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
      this.form.setFieldsValue({recruit_images:this.imageUrls})
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'recruit_images') {
          this.imageUrls.push(image.path)
          this.form.setFieldsValue({recruit_images:this.imageUrls})
        } else {
          this.imageUrl = image.path
        }
      }
      this.imagesVisible = false
    },
    handleSearch(val){
      let obj = {q:val}
      this.getSurvey(obj)
    },
    handleSearchClass(val){
      let obj = {'q':val}
      this.getClassCategory(obj)
    },
    onChangeCascader(value, selectedOptions) {
        this.channelsId = value
    },
  }
}
</script>
