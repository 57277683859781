<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>客户姓名/手机号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.fuzzy" placeholder="客户姓名/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>交易支付号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.out_trade_no" placeholder="交易支付号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="报名时间" >
                      <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" :allowClear="false" allowClear v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/>
                    </a-form-item>

                    <a-form-item class="block-line" label="所属招募" >
                      <a-select allowClear v-model="searchData.class_online_id" placeholder="请选择" @search='handleSearch' showSearch :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of recruitList" :key="index" :value="d['class_online_id']">{{ d['recruit_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>

                    <a-form-item class="block-line" label="所属校区" >
                      <a-select allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择" showSearch :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>

                    <a-form-item class="block-line" label="课程科目" >
                        <a-select allowClear v-model="searchData.course_cate_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of categorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>

                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="online_recruit_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="action" slot-scope="text,record">
          <a href="javascript:;" @click="toDel(record)">删除</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '上课校区', dataIndex: 'studio_name', key: '0' },
  { title: '招募名称', dataIndex: 'recruit_name', key: '2' ,ellipsis:true},
  { title: '班级名称', dataIndex: 'class_name', key: '1' ,ellipsis:true},
  { title: '课程收费', dataIndex: 'fee_title', key: 'fee_title' },
  { title: '学员名称', dataIndex: 'student_name', key: '3' },
  { title: '手机号码', dataIndex: 'cellphone', key: '4' },
  { title: '性别', dataIndex: 'gender', key: '5' },
  { title: '订单号码', dataIndex: 'out_trade_no', key: '6',width:'160px' },
  { title: '支付金额', dataIndex: 'sale_price', key: '7' ,width:'80px' ,align:'center'},
  { title: '报名时间', dataIndex: 'created_at', key: '8' },
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' }},

]

import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges";
import authority from '@/common/mixins/authority'
import moment from 'moment'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      recruitList:[],
      current: 1,
      categorys: [],
      studios: [],

      searchData: {},
      authType:['market','weipages'],
      visible: false
    }
  },
  mixins: [ ranges , authority, tableMixins ],
  created() {
    this.getStudio()
    this.getRecruit()
    this.getCategory()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('weipagesRecruitMemberAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    async getRecruit(obj){
      let res = await this.$store.dispatch('weipagesRecruitAction', { params: obj })
      this.recruitList = res.items
    },
    async getCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.categorys = res.data
    },
    handleSearch(e){
      let obj = {q:e}
      this.getRecruit(obj)
    },
    toDel(item){
      let that = this
      this.$confirm({
          title: `您确定删除该报班记录??`,
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
          return new Promise(async (resolve, reject) => {
              await that.$store.dispatch('weipagesRecruitDeleteMemberAction', {class_online_id : item.online_recruit_id})
              .then(res=>{
                  that.$message.success('操作成功~')
                  that.getList()
                  resolve(res)
              })
              .catch(err=>{
                  reject(err)
              })
          }).catch(error => console.log(error))
          }
      })
    },
    async toExport(){
      this.exportLoading=true
      let obj = {}
      let { searchData } = this
      for (let k in searchData) {
        if (k === 'created_at') {
          obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
          obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
        } else {
          obj[k] = searchData[k]
        }
      }
      let res = await this.$store.dispatch('weipagesRecruitExportAction', { search:obj,sort:this.searchParams.sort })
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `报班记录.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading=false;
    }
  }
}
</script>
